import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from "gatsby-plugin-react-i18next";
import SingleTemplate from "./../@whitespace/gatsby-theme-wordpress/templates/SingleTemplate";
import { Content404 } from "./../components/Content404";

const Page404 = (props) => {
    const {location} = props;
    const {t, i18n} = useTranslation();

    // Set available languages, can't find a good way to do this dynamically and i18n returns only sv
    const languages = ['sv', 'en'];
    const pathname = location?.pathname ? location?.pathname.split("/")[1] : "";
    const language = languages.includes(pathname) ? pathname : "sv";

    // TODO: This doesn't seem to work, 404 is always sv
    useEffect(() => {
        if(language !== i18n.language) {
            i18n.changeLanguage(language);
        }
    }, []);

    const title = t('404.title');
    const seoTitle = t('404.seoTitle');

    const context = {
        language: language,
        contentNode: {
            is404: true,
            title: seoTitle,
            hero: {
                heroTitle: title,
            },
            titleHasQuirkyText: false,
            language: language,
            pageAppearance: {
                background: 'dark',
                foreground: 'light',
            },
        },
    };
    return (
        <SingleTemplate pageContext={context}>
            <Content404 />
        </SingleTemplate>
    );
};

export default Page404;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

import React from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";
import Button from './../Button/Button';
import "./Content404.scss";

const Content404 = ({title, content}) => {
    const {t} = useTranslation();

    const subtitle = t('404.subtitle');
    const text = t('404.description');
    const button = t('404.button');

    return (
        <div className="content-404 wrapper--article">
            <h2 className="content-404__title">{subtitle}</h2>
            <div className="content-404__description"><p>{text}</p></div>

            <Button link="/">{button}</Button>
        </div>
    );
};

export default Content404;
